<template>
  <article class='ck-content content content--article'>
    <div class='hemingway_browse' v-if='!no_siblings'>
      <slot name='siblings'></slot>
    </div>
    <div>
      <div id='contents'>
        <div class='hemingway__read' v-if='read_time'><i class='fas fa-clock'></i>{{read_time}}</div>
        <slot name='content'></slot>
      </div>
    </div>
    <div class='hemingway__toc'>
      <div id='toc'>
        <ul>
          <li :class='`toc${t.tag}`' v-for='t in toc'><a :href='`#${t.id}`'>{{t.name}}</a></li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  props: ["url", "text", "read_time", "no_siblings"],
  data: function () {
    return {
      isShown: false,
      toc: []
    }
  },
  mounted () {
    this.getContents();
  },
  methods: {
    toggleContent (e) {
      if (this.isShown == false) {
        this.getContents()
        this.isShown = true;
      } else {
        this.isShown = false;
      }
    },
    getContents () {
      var that = this;
      var headers = document.getElementById("contents").querySelectorAll("h1, h2, h3, h4, h5, h6")
      headers.forEach(function (element) {
        var tag = element.tagName;
        console.log(tag)
        var readable = element.textContent;
        var id = readable.replace(/\s+/g, '-')
        console.log(id)
        element.setAttribute("id", id);
        that.toc.push({tag: tag, name: readable, id: id})
      })
    }
  },
}
</script>

