<template>
  <div class='quick_notes nasa__block'>
    <header class='nasa__block-header'>
      <h1><i class="fas fa-sticky-note icon"></i>Quick Notes</h1>
      <div class='button-group' v-if='can'>
        <a href='#' v-if='!isShown' class='button button--secondary button--small' @click.prevent.stop="isShown = !isShown"><i class='fas fa-edit'></i> Edit</a>
        <a href='#' v-if='isShown' class='button button--secondary button--small' @click.prevent.stop="isShown = !isShown">Cancel Editing</a>
       
      </div>
    </header>
    <main>
      <div v-if='!isShown' class='ck-content' v-html='notes'></div>
      <div class='quick_notes__writer' v-if='isShown'>
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
import Writer from './writer.vue';

export default {
  props: ["company_url", "prenotes", "can"],
  components: {
    Writer
  },
  methods: {
    save () {
      var that = this;

      that.$api.put(that.company_url, {
        company: {
          notes: that.notes
        }
      })
      .then(function (response) {
        that.notes = response.data.notes;
        that.isShown = false
      })
    }
  },
  data () {
    return {
      notes: this.prenotes || "",
      isShown: false
    }
  }
}
</script>

