<template>
  <div>
    <div class='c-liongard__nav'>
      <a href='#' :class='{"selected": activeIndex == index}' @click.toggle='switchName(index)' v-for='(n, index) in names'>{{n}}</a>
    </div>
  </div>
</template>

<script>

export default {
  props: ["names"],
  data: function () {
    return {
      activeIndex: 0
    }
  },
  mounted () {
    document.querySelector(`#LG-0`).style.display = "block"
  },
  methods: {
    switchName (i) {
      document.querySelector(`#LG-${this.activeIndex}`).style.display = "none";
      this.activeIndex = i
      document.querySelector(`#LG-${i}`).style.display = "block";
    }
  }
}
</script>

