
const state = {
  sub_open: 0,
}

const getters = {
  showRinger (state) {
    return state.sub_open == 1 ? true : false
  },
  showSearcher (state) {
    return state.sub_open == 2 ? true : false
  },
  showDropper (state) {
    return state.sub_open == 3 ? true : false
  },
  showVault (state) {
    return state.sub_open == 4 ? true : false
  },
  showSyncBar (state) {
    return state.sub_open == 5 ? true : false
  }
}

const actions = {}

const mutations = {
  changeSub (state, newSub) {
    state.sub_open = newSub;
  },
  changeHeader (state, newHeader) {
    state.header_open = newHeader;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}