<template>


  <div class='password'>
    <flash-message></flash-message>
    <a href='#' v-if="!isShown && !compact" class='button button--secondary' @click.prevent='getPassword()'><i class='fas fa-eye'></i>{{reveal_name}}</a>
    <transition name='fade'>
      <div class='inputer' style='width: 100%' v-if="isShown && !compact">
        <input type='text' readonly v-model='password'>
        <aside>
          <a href='#' v-if='!isCopied' @click.prevent="copy($event)"><i class='fas fa-copy'></i></a>
          <a href='#' v-if='isCopied' @click.prevent="copy($event)">Copied!</a>
        </aside>
      </div>
    </transition>
    <div class='password__options' v-if='!compact && !isShown'>
      <input type='text' readonly value='' style='display: none;'>
      <a href='#' v-if='!isCopied' @click.prevent="copy($event)" class='button button--secondary'><i class='fas fa-copy'></i>{{copy_name}}</a>
      <a href='#' v-if='isCopied' @click.prevent class='button button--secondary'>{{copied_name}}</a>
    </div>
    <div v-if='compact'>
      <input type='text' readonly value='' style='display: none;'>
      <a href='#' v-if='!isCopied' @click.prevent="copy($event)" class='button button--secondary'><i class='fas fa-copy'></i>{{copy_name}}</a>
      <a href='#' v-if='isCopied' @click.prevent class='button button--secondary'>{{copied_name}}</a>
    </div>
    <transition name='fade'>
      <div class='password__lovely' v-if='isShown && isEasy' v-html='lovely'>
      </div>
    </transition>
    <div v-if='isShown && !isEasy' class='password__info'>
      <div class='button-group'>
        <a href='#' class='button button--small button--secondary' @click.prevent.stop="isEasy = true"><i class="fas fa-binoculars"></i>{{easy_read_name}}</a>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: "password-viewer",
  props: ["url", "compact", "preeasy_read_name", "prereveal_name", "precopy_name", "precopied_name"],
  computed: {
    lovely () {
      var that = this;
      let result = '';
      for (let character of that.password) {
        if (/^\d+$/.test(character)) {
          // it is a number
          result += `<span class='lovely--number'>${character}</span>`;
        } else if (character.match(/[A-Z|a-z]/i)){
          // it is a letter
          result += `<span>${character}</span>`;
        } else {
          // it is a special character or something weeeeeiiiiirrdddd.
          result += `<span class='lovely--special'>${character}</span>`;
        }
      }
      return result;
    }
  },
  methods: {
    getPassword () {
      var that = this;
      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.isShown = true;
        that.password = data.password;

        that.flash("Revealed!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      })

    },
    copy (e) {

      var that = this;


      if(that.password == "") {
        var xhttp = new XMLHttpRequest();
        xhttp.open("GET", that.url, false);
        xhttp.send();
        var response = JSON.parse(xhttp.responseText);
        that.setClipboard(response["password"], e)
      } else {
        that.setClipboard(that.password, e)
      }
      // if (that.password == "") {
      //   fetch(that.url)
      //   .then((resp) => resp.json())
      //   .then(function(data) {
      //     that.password = data.password;
      //     that.isShown = true;
      //     that.setClipboard(data.password, e)
      //   })
      // } else {
      //   that.setClipboard(that.password, e)
      // }
    },
    setClipboard(value, e) {

      var that = this;

      Clipboard.copy(value);
      that.isCopied = true;

      that.flash("Copied!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );

      setTimeout(function(){ that.isCopied = false; }, 2000);


      // navigator.permissions.query({
      //   name: 'clipboard-write'
      // }).then(permissionStatus => {
      //   console.log(permissionStatus.state);

      //   permissionStatus.onchange = () => {
      //     console.log(permissionStatus.state);
      //   };
      // });

      // navigator.clipboard.writeText(value)
      // .then(() => {
      //   that.isCopied = true;
      // })
      // .catch(err => {
      //   console.log("error copying")
      // });
    }
  },
  data: function () {
    return {
      isShown: false,
      isEasy: false,
      isCopied: false,
      password: "",
      easy_read_name: this.preeasy_read_name || "Easy Read",
      reveal_name: this.prereveal_name || "Reveal",
      copy_name: this.precopy_name || "Copy",
      copied_name: this.precopied_name || "Copied!"
    }
  }
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>
