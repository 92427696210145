
<template>
  <div @keydown.esc='hide'>
    <a href='#' v-if='can && !isShown' @click.prevent.stop='toggle' class='company-sidebar__new'><i class='fas fa-plus'></i>New...</a>
    <a href='#' v-if='can && isShown' @click.prevent.stop='toggle' class='company-sidebar__new'><i class='fas fa-times'></i>Close</a>
    <slot v-if='isShown'></slot>
  </div>
</template>

<script>
import '../lib/editor';
export default {
  props: ["can"],
  components: {
    
  },
  methods: {
    toggle () {
      this.isShown = !this.isShown;
      if (this.isShown) {
        document.querySelector(".company-jump").style.display = "block";
      } else {
        this.hide()
      }
    },
    hide () {
      document.querySelector(".company-jump").style.display = "none";
    }
  },
  mounted () {
    var that = this;
  },
  data() {
    return {
      isShown: false
    }
  }
}
</script>
